.paymentProcess__three table {
    float: right;
    margin-right: 30px;
}
.paymentProcess__table {
    width: 100%;
    margin-right: 10px;
}
.pending {
    color: red;
}
.paymentProcess__three {
    width: 50% !important;
}
.paymentProcess__flex-row {
    width: 50%;
}
.process-heading {
    font-size: 1.125rem;
    color: #686868;
    font-weight: 700;
}
.online-method {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #bdbec0;
    padding-bottom: 3rem;
}
.method-wrap img {
    width: 150px;
}
.method-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #686868;
    width: 30%;
    height: 141px;
    border-radius: 8px;
}
.vendor-booking-form .form-group {
    flex-basis: 25%;
    margin-right: 30px;
}
.vendor-booking-detail h2 {
    font-size: 1.125rem;
    color: #686868;
}
.payment-process-2 .method-wrap {
    width: 340px;
}
.payment-process-detail {
    width: 60%;
}
.payment-process-detail li {
    font-size: 1rem;
    color: #212529;
}
