.paymentDetail__Two {
    width: 15% !important;
}
.issueDate {
    color: var(--primary) !important;
}
.issueDate span {
    color: #0000009b;
    margin-left: 16px;
}
.comment-history-content {
    background: #f3f3f5;
    padding: 24px;
    border-radius: 12px;
    flex-basis: 30%;
    margin-right: 36px;
}
.comment-history-role {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.comment-history-text p {
    color: #605bff;
    text-align: center;
    border: 1px solid #2f77b2;
    border-radius: 23px;
    padding: 6px 16px;
    background: #fff;
    margin-bottom: 1rem !important;
}
.payment-status p {
    color: #707070;
}
