/* div{
    background-color: red;
} */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0px !important;
}
a {
    color: #495057 !important;
}

:root {
    --primary: #605bff;
    --light: #f3f3f5;
    --bg-gray: #f3f3f5;
    --bg-table-head: #f6f6f6;
    --bg-color: #fcfcfc;
    --table-bg: --bg-color !important;
    --badge-pill-padding-x: 0.7em !important;
    --table-cell-padding: 1rem 0.5rem !important;
    --border-md-gray: #e1e1e1;
    --border-dashed: #c2cdda;
    --white: #fff;
    --black: #000;
    --dark: #343a40;
    --table-bottom-color: #707070;
    --border-radius: 4px;
    --font-size: 1rem;
    --font-size-sm: 0.75rem;
    --font-size-md: 0.8125rem;
    --font-semi-bold: 600;
    --font-bold: 700;
    --btn-width: 110px;
    --btn-height: 29px;
    --danger: #e03b24;
    --light-gray: #b1b1b1;
    --toast-font-color: #686868;
    --disabledBtn: #707070;
    --nav-item--height: 30px;
    --w-100: 100%;
    --workflow-border: #bdbec0;
    --btn-color: #212529;
}
button {
    cursor: pointer;
}

/* Scrollbar Style */
.sidebar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(186, 186, 186);
    border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 10px;
}

/* Classes */
.mb-0 {
    margin-bottom: 0px !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.mb-2 {
    margin-bottom: 0.75rem !important;
}
.mb-3 {
    margin-bottom: 1.5rem !important;
}
.mb-4 {
    margin-bottom: 2.3rem !important;
}
.mb-5 {
    margin-bottom: 3rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.75rem !important;
}
.mt-3 {
    margin-top: 1.5rem !important;
}
.mt-4 {
    margin-top: 2.3rem !important;
}
.mt-5 {
    margin-top: 3rem !important;
}
.py-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.h-100 {
    height: 100% !important;
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: 4px !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}
.btn-primary {
    color: #fff !important;
    background-color: #605bff !important;
    border-color: #605bff !important;
}
.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.6875rem !important;
}
.main-btn {
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 1rem !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}
.app-main-wrapper {
    padding: 0 29px;
}
.heading {
    margin-bottom: 1rem;
}
.heading h2 {
    font-size: var(--font-size);
    font-weight: var(--font-semi-bold);
    color: var(--black);
}
.card-wrapper {
    display: flex;
}
.card {
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22%;
    margin-right: 16px;
    padding: 12px 0px;
    border-radius: 10px;
}
.card-icon {
    padding-left: 15px;
}
.card-icon span {
    padding: 7px;
    border-radius: 23px;
}
.icon-emp {
    background-color: #5b93ff33;
}
.icon-emp path {
    fill: #5b93ff;
}
.card-detail {
    padding-right: 15px;
    text-align: right;
}
.card-detail h3 {
    font-size: 1.2rem;
    color: var(--black);
    font-weight: var(--font-semi-bold);
    text-align: right;
    line-height: 1rem;
}
.card-detail span {
    text-align: center;
    color: var(--btn-color);
    font-size: var(--font-size-sm);
}
.icon-depart path {
    fill: #af7171;
}
.icon-depart {
    background-color: #ffe9e1;
    padding: 5px;
}
.card-icon div {
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
}
.my-table {
    margin-top: 1.5rem;
}
.table-edit {
    box-shadow: 0 2px 10px #0000001a, 0 4px 8px #0000;
    width: 26px;
    height: 26px;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.table-edit svg {
    width: 12px;
    height: 12px;
}
.table-edit path {
    fill: var(--primary);
}
.form-group label {
    display: block !important;
    color: var(--primary) !important;
    font-size: var(--font-size-sm) !important;
    margin-bottom: 0.5rem;
    text-align: left;
    height: auto !important;
}
.form-group input {
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    /* width: 100%; */
    min-width: 0;
    padding: 4px 11px;
    color: #000000d9;
    line-height: 1.5715;
    background-image: none;
    transition: all 0.3s;
    border: 0px !important;
    border-bottom: 0.25px solid rgba(189, 190, 192, 0.5) !important;
    border-radius: 0 !important;
    font-size: 0.6875rem !important;
    background-color: transparent !important;
}
.form-group input:hover {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
}
.form-group input:focus {
    box-shadow: none;
    outline: none;
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
}
.form-group .ant-row {
    flex-direction: column;
}
.form-group .ant-select-selector {
    border: 0px !important;
    border-bottom: 0.25px solid rgba(189, 190, 192, 0.5) !important;
    background-color: transparent !important;
}
.form-group .ant-select-selector:hover {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
    outline: none !important;
    box-shadow: none !important;
}
.form-group .ant-select-selector:focus,
.form-group .ant-select-selector:active {
    border-right-width: 1px !important;
    outline: none !important;
    box-shadow: none !important;
}
/* .ant-select-focused:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 0px !important;
} */
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    border-color: #40a9ff !important;
    box-shadow: none !important;
}
.ant-table-content,
table {
    background-color: transparent;
}
tr {
    background-color: #fff;
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid transparent !important;
}
.ant-table table {
    border-spacing: 0px 5px !important;
    background-color: #e5e5e5;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    padding: 12px 16px !important;
    font-size: var(--font-size-sm);
}
.ant-table-thead > tr > th {
    color: var(--primary) !important;
}
.checkbox-group {
    display: flex;
    align-items: center;
}
.checkbox-group label {
    margin-bottom: 0px;
    margin-right: 10px;
}
.ant-picker {
    width: 100%;
    margin-bottom: 24px;
    border: 0px !important;
    padding: 0 !important;
    margin-bottom: 24px !important;
}
.ant-picker input {
    padding-left: 10px !important;
    padding-bottom: 4px !important;
}
.ant-picker-focused {
    box-shadow: none !important;
}
.form-time input {
    width: 100%;
    margin-bottom: 24px;
}
.d-flex {
    display: flex;
}
.mr-2 {
    margin-right: 0.75rem;
}
.all-emp-form .form-group {
    flex-basis: 45%;
}
.ant-picker-input input {
    border-bottom: 0px !important;
}
.ant-picker-input {
    border-bottom: 0.25px solid rgba(189, 190, 192, 0.5) !important;
}
.ant-picker-input:hover {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
}
.ant-picker-input:focus,
.ant-picker-input:active {
    box-shadow: none;
    outline: none;
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
}
.icon-open {
    position: absolute;
    left: 43px;
    color: var(--primary) !important;
    top: 28px;
    z-index: 2;
}
.sidebar-icon {
    position: relative;
}
.sidebar-icon,
.menu-fold {
    display: none;
}
.ant-menu-sub.ant-menu-inline {
    background-color: var(--white) !important;
}
label.ant-checkbox-wrapper {
    color: var(--primary);
    font-size: var(--font-size-sm);
}
.all-emp-form .ant-picker input {
    padding-bottom: 7px !important;
}
.all-emp-form .form-group {
    margin-right: 16px;
}
.no-margin .ant-picker {
    margin-bottom: 0px !important;
}
.table-wrapper table {
    width: 100%;
}
.table-wrapper table th {
    background-color: #f6f6f6 !important;
    color: #605bff !important;
    border: 0;
    font-size: 0.75rem !important;
    font-weight: 600;
    border-top: 0px !important;
}
.table-wrapper table tr {
    border-bottom: 1px solid #bdbec0;
}
.table-wrapper table th,
.table-wrapper table td {
    padding: 1rem 0.5rem 1rem 1rem !important;
}
.permission-search {
    background-color: #f3f3f5 !important;
    border: 1px solid #bdbec0 !important;
    padding: 4px 8px;
    border-radius: 6px !important;
}
.assign-table--td {
    font-size: 0.6875rem !important;
}
.permission-search:focus {
    outline: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    text-align: left;
}
.dashboard-title {
    border-radius: 5px;
    background-color: #f3f3f5;
    width: 172px;
    font-size: 0.6875rem;
    height: 29px;
    padding: 1rem;
    margin-bottom: 0;
    color: var(--primary);
    display: flex;
    align-items: center;
}
.dashboard-title img {
    margin-right: 5px;
}
.assign-inner .form-group {
    flex-basis: 30%;
    margin-right: 36px;
}

.assign__checkbox {
    text-align: center;
    flex-basis: 18%;
}
.assign-wrapper {
    background-color: var(--white);
}
.assign-inner {
    padding: 22px;
}
.assign__btn {
    padding-bottom: 30px;
    margin-right: 20px;
    margin-top: 30px !important;
}
.assign-inner .d-flex:nth-child(1) {
    align-items: center;
    margin-bottom: 24px;
}
.ant-select-item-option-content {
    font-size: 0.687rem;
}
.align-item-center {
    align-items: center;
}
.space-between {
    justify-content: space-between;
}
@media screen and (max-width: 991.98px) {
    .sidebar {
        width: 250px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .dashboard-wrapper {
        left: 0px;
        width: 100%;
        z-index: 1;
        position: relative;
    }
    .sidebar {
        left: -100%;
        transition: 550ms;
    }
    .sidebar.active {
        left: 0;
        transition: 450ms;
    }
    .icon-close {
        position: absolute;
        right: 14px;
        top: 43px;
        z-index: 999;
    }
    .sidebar-icon {
        display: block;
    }
    .menu-fold {
        display: block;
    }
    .card {
        width: 48%;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .card-wrapper {
        flex-flow: row wrap;
    }
    .my-table {
        overflow-x: auto;
    }
    .d-flex {
        flex-flow: row wrap;
    }
    .all-emp-form .form-group {
        flex-basis: 45%;
    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .generate-table {
        overflow-x: auto;
    }
    .permission-form table {
        overflow-x: auto;
        width: 991px;
    }
    .permission-form-inner {
        overflow-x: auto;
    }
}
@media screen and (max-width: 767.98px) {
    .leave-employee-drawer .ant-space {
        flex-flow: row wrap;
    }
    .search-emp .form-group {
        width: 48%;
        margin-right: 20px;
    }
}
@media screen and (max-width: 599.98px) {
    .card {
        width: 46%;
    }
}
@media screen and (max-width: 414px) {
    .card {
        width: 100%;
    }
    .search-emp {
        flex-flow: row wrap;
    }
    .search-emp .form-group {
        width: 100%;
        margin-right: 0px;
    }
}
