.single {
    display: flex;
    align-items: center;
}
.container {
    margin-right: 15px;
}
.workflow {
    width: 250px;
}
.workflow__heading {
    display: flex;
    justify-content: space-between;
    border: 1px solid #bdbec0;
    background: #f3f3f5;
    padding: 8px 16px;
    border-radius: 23px;
}
.workflow__heading h4 {
    color: #605bff;
    font-size: 0.6875rem;
    margin-bottom: 0;
    font-weight: 700;
}
.workflow__heading button {
    padding: 0;
    background: #fff;
    border: 0;
    border-radius: 30px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.workflow__heading img {
    width: 10px;
    height: 9px;
}
.add-user p {
    font-size: 0.8125rem;
    color: #686868;
    margin-bottom: 7px !important;
}
.add-user button {
    font-size: 0.6875rem;
    color: #605bff;
    border: 1px solid #605bff;
    border-radius: 6px;
    background: transparent;
    padding: 4px 14px;
}
.requester__img {
    margin-right: 8px;
}
p.requester__name {
    font-size: 0.6875rem;
    font-weight: 600;
    color: #686868;
    margin-bottom: 0;
}
p.requester__auth {
    color: #8e8ea1;
    font-size: 0.6875rem;
}
.requester__del {
    margin-left: 10px;
    visibility: hidden;
    transition: 0.2s ease-out;
}
.requester__del button {
    background: #fff;
    border: 0;
    display: flex;
    margin-top: 4px;
}
.default-approver h4 {
    font-size: 0.6875rem;
    font-weight: 700;
    color: #605bff;
}
.add-user button {
    font-size: 0.6875rem;
    color: #605bff;
    border: 1px solid #605bff;
    border-radius: 6px;
    background: transparent;
    padding: 4px 14px;
}
.add-workflow__btn button {
    border: 0px;
    background: transparent;
    display: flex;
    align-items: center;
    width: 112px;
    justify-content: space-between;
    margin-top: 4px;
}
.my-container {
    display: flex;
}
.workflow-wrapper {
    background-color: #fff;
    padding: 20px;
    overflow: auto;
}
.table {
    width: 100%;
}
.approval-table td {
    color: #000;
}
.approval-table table th {
    background-color: #f6f6f6 !important;
    color: #605bff !important;
    border: 0;
    font-size: 0.75rem !important;
    font-weight: 600;
    border-top: 0px !important;
}
.approval-table th,
.approval-table td {
    padding: 1rem 0.5rem !important;
}
.approval-table img {
    margin-right: 12px;
}
.ant-modal-title {
    font-size: 1.125rem !important;
    color: #605bff !important;
    text-align: center;
}
.ant-modal-content {
    border-radius: 10px !important;
}
.ant-modal-header {
    border-radius: 10px 10px 0 0 !important;
}
