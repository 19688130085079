.avatar-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.header-wiget-wrapper {
    padding: 0 18px;
}
.avatar-wrap .anticon-down {
    display: none;
}
.avatar path {
    fill: var(--primary);
}
.avatar {
    padding: 5px 7px;
    background: #fff;
    border-radius: 53px;
}
.avatar-wrap p {
    color: var(--primary);
    font-size: var(--font-size-sm);
}
