.payment-text {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
}
.payment-text h2 {
    font-size: 2rem;

    margin-bottom: 20px;
    text-align: center;
}
