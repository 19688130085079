.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 275px;
    transition: all 0.5s ease;
    box-shadow: 10px 0 10px -18px #000;
    padding-right: 25px;
    padding-left: 25px;
    z-index: 5;
    overflow-y: auto;
    background-color: #fff;
}
.dashboard-wrapper {
    position: relative;
    top: 0;
    left: 275px;
    width: calc(100% - 275px);
    transition: all 0.5s ease;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
.logo-detail {
    height: 60px;
    position: relative;
    width: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-name {
    margin-top: 1rem;
}
.logo-name img {
    margin-top: 1.5rem;
    width: 100%;
}
.sidebar-menu {
    margin-top: 3rem;
}
.sidebar-menu .ant-menu-inline {
    border: 0px !important;
}
.sidebar-menu li {
    font-size: var(--font-size-sm);
}
.sidebar-menu .ant-menu-item-selected {
    color: #fff;
}
.sidebar-menu .ant-menu-item-selected:hover {
    color: #fff !important;
}
.sidebar-menu .ant-menu-item-selected:after {
    display: none;
}
.sidebar-menu .ant-menu-item-selected {
    background-color: var(--primary) !important;
}
.sidebar-menu .ant-menu-item {
    height: 30px !important;
    border-radius: 5px;
}
/* .sidebar-menu .ant-menu-light .ant-menu-submenu-title:hover{
    color: initial !important;
}
.sidebar-menu .ant-menu-item:hover{
    color: initial !important;
} */
